import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import SectionButton from "./SectionButton";
import "./HeroSection.scss";
import { Link } from "./../util/router.js";

function CareerSection(props) {
  return (
    <>
        <div class="help-articles">
				<div class="container">
					<div class="row">
						<div class="col-lg-6 col-md-6 col-12">
							<div class="single-help-box">
								<Link to="/marketing-and-sales-full-time">
									{/* <img src={require('../images/embtel.png')} width="100"/> */}
									<h4 className="">Marketing & Vertrieb Vollzeit (m/f/d)</h4>
									<span class="badge badge-lg badge-success">Vollzeit</span>
									<p>Zur Verstärkung unseres Teams suchen wir ab sofort eine/n engagierte/n Mitarbeiter/in für unsere Vertriebsabteilung.</p>
									<p className="apply-link">Jetzt bewerben</p>
									{/* <a href="" className=" theme-btn solid-button-one">Apply Now</a> */}
								</Link>
							</div>
						</div>
						<div class="col-lg-6 col-md-6 col-12">
							<div class="single-help-box">
								<Link to="/marketing-and-sales-part-time">
									{/* <img src={require('../images/embtel.png')} width="100" /> */}
									<h4 className="" >Marketing & Vertrieb Teilzeit (m/f/d)</h4>
									<span class="badge badge-lg badge-success">Teilzeit</span>
									<p>Zur Verstärkung unseres Teams suchen wir ab sofort eine/n engagierte/n Mitarbeiter/in für unsere Vertriebsabteilung.</p>
									<p className="apply-link">Jetzt bewerben</p>
									{/* <a href="" className=" theme-btn solid-button-one head-btn">Apply Now</a> */}
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
    </>
  );
}

export default CareerSection;
