import React from "react";
import ApplyNowFull from "../components/ApplyNow";
import HeroApply from "../components/HeroApply";

function ApplyPageFull(props) {
  return (
    <>
        <HeroApply />
        <ApplyNowFull />
    </>
  );
}

export default ApplyPageFull;
