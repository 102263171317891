import React from "react";
import ApplyNow from "../components/ApplyNow";
import HeroApply from "../components/HeroApply";

function ApplyPage(props) {
  return (
    <>
        <HeroApply />
        <ApplyNow />
    </>
  );
}

export default ApplyPage;
