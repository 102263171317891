import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";


function OurTestimonials(props) {
  return (
    <Section className="SectionComponent hero section is-block is-relative our-testimonials">
        <div className="testimonial-section-classic style-one pos-r md-pt-40 md-pb-200">
			<img src={require('../images/shape/33.svg')} alt="" className="main-bg" />
			<div className="shape-wrapper">
				<img src={require('../images/shape/34.svg')} alt="" className="shape-one img-shape" />
			</div>
			<div className="shape-wrapper">
				<img src={require('../images/home/2.jpg')} alt="" className="people" />
				<img src={require('../images/home/3.jpg')}  alt="" className="people" />
				<img src={require('../images/home/4.jpg')} alt="" className="people" />
				<img src={require('../images/home/5.jpg')} alt="" className="people" />
				<img src={require('../images/home/6.jpg')} alt="" className="people" />
				<img src={require('../images/home/7.jpg')} alt="" className="people" />
			</div>
			<div className="container">
				<div className="theme-title-one text-center">
					<div className="icon-box hide-pr">
						<svg className="bg-shape" width="80px" height="80px">
							<defs>
								<linearGradient id="PSgrad_55" x1="0%" x2="0%" y1="100%" y2="0%">
								  <stop offset="0%" stop-color="rgb(255,175,185)" stop-opacity="1" />
								  <stop offset="100%" stop-color="rgb(255,70,159)" stop-opacity="1" />
								</linearGradient>
							</defs>
							<path fill-rule="evenodd"  fill="url(#PSgrad_55)"
								 d="M40.000,-0.000 C62.091,-0.000 80.000,17.908 80.000,40.000 C80.000,62.091 62.091,80.000 40.000,80.000 C17.909,80.000 0.000,62.091 0.000,40.000 C0.000,17.908 17.909,-0.000 40.000,-0.000 Z"/>
						</svg>
						<img src={require('../images/icon/icon7.svg')} alt="" className="icon" />
					</div>
					<h2 className="main-title underline"><span>Was denkt unser Kunde </span><br/> <span>über uns?</span></h2>
				</div>

				<div className="inner-container">
					<div className="main-content">
						<div className="classic-testimonial-slider">
							<div className="item">
								<p>Das ist in der Tat das Beste, was es gibt! Die Zusammenarbeit mit Embtel Solutions war eine absolute Freude, das Team ist sowohl der Produktqualität als auch der Kundenzufriedenheit verpflichtet.</p>
								<div className="logos pt-30">
									<img className="mr-10" width="35" src={require('../images/yelp-logo.png')} width="100"></img>
									<img src={require('../images/yelp-star.png')} width="200"></img>
								</div>
								<h6 className="name">Veronica A.</h6>
								<span className="designation">San Francisco</span>
							</div>
							{/* <div className="item">
								<p>ommodo consequat. Duis aute irure dolor in reprehendert voluptate velit esse cillum dolore eu fugiat nulla Excepteu sint occaecat cupidat non proident, sunt in culpa qui officia deserunt mollit anim id est lrum.</p>
								<h6 className="name">Zubayer al hasan</h6>
								<span className="designation">Senior Developer, Envato.</span>
							</div>  */}
						</div>
					</div>
				</div>
			</div>
		</div>
      
    </Section>
  );
}

export default OurTestimonials;
