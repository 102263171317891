import React, { useState } from "react";
import NavbarContainer from "./NavbarContainer";
import { Link } from "./../util/router.js";
import { useAuth } from "./../util/auth.js";

function Navbar(props) {
  const auth = useAuth();
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <NavbarContainer spaced={props.spaced}>
      <div className="container">
        <div className="navbar-brand">
          <div className="navbar-item">
            <Link to="/">
              <img className="image" src={props.logo} alt="Logo" />
            </Link>
          </div>
          <div
            className={"navbar-burger burger" + (menuOpen ? " is-active" : "")}
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <span />
            <span />
            <span />
          </div>
        </div>
        <div className={"navbar-menu" + (menuOpen ? " is-active" : "")}>
          <div className="navbar-end eng-nav">
            {/* <a class="navbar-item" href="#about">About</a>
            <a class="navbar-item" href="#features">Features</a>
            <a class="navbar-item" href="#services">Services</a>
            <a class="navbar-item" href="#projects">Projects</a> */}
            <a class=" theme-btn solid-button-one head-btn" href="tel:+4923639099921">Call Us Now</a>
            
          </div>
          <div className="navbar-end ger-nav">
            {/* <a class="navbar-item" href="#about">Über</a>
            <a class="navbar-item" href="#features">Eigenschaften</a>
            <a class="navbar-item" href="#services">Leistungen</a>
            <a class="navbar-item" href="#projects">Projekte</a> */}
            <a class=" theme-btn solid-button-one head-btn" href="tel:+4923639099921">Ruf uns jetzt an</a>
            
          </div>
        </div>
      </div>
    </NavbarContainer>
  );
}

export default Navbar;
