import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";


function Consultation(props) {
  return (
    <Section id="get-quote" className="SectionComponent hero section is-block is-relative our-consultation">
        <div class="consultation-section pos-r pt-50 pb-150 md-pt-80">
		<iframe className="animate-svg" src="https://embtel.us/images/demo2.html"></iframe>
				<div class="container">
					<div class="row">
						<div class="col-lg-6 order-lg-last">
							<div class="theme-title-one mb-85 md-mb-50">
								<h2 class="main-title underline"><span>Holen Sie sich ein </span><br/> <span>kostenloses Website-Angebot </span> <br/><span>von unserem Experten.</span></h2>
							</div> 
							<div id="thankyou_message" style={{display:'none'}} className="thankyou_message">
								<div class="alert alert-success" role="alert">
									Your message has been sent successfully. We will get back to you soon.
								</div>
							</div>
							<div class="theme-form-style-one">
								<form id="gform" method="POST" action="https://script.google.com/macros/s/AKfycbw2G2X4dwSLrMrQw90Sti8lMP8B7SI_izWm_d4YSg/exec">
									<div class="row form-elements">
										<div class="col-lg-6">
											<input id="name" name="name" type="text" placeholder="Name*" />
										</div>
										<div class="col-lg-6">
											<input id="email" name="email" type="email" placeholder="Email*" />
										</div>
										<div class="col-12">
											<input id="website" name="website" type="text" placeholder="Website Link*" />
										</div>
										<div class="col-12">
											<textarea id="message" name="message" placeholder="Message"></textarea>
										</div>
										<div class="col-12 pb-20">
											<div class="g-recaptcha" data-sitekey="6Lf3zcYUAAAAANjCg8KGkycAokNiZ4kNbB_Rtj5c"></div>
										</div>
									</div>
									<button class="theme-btn solid-button-one">Anfrage senden</button>
								</form>
							</div>
						</div>
						<div class="col-lg-6 order-lg-first">
							
						</div>
					</div>
				</div>
			</div> 
      
    </Section>
  );
}

export default Consultation;
