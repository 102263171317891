import React from "react";
// import HeroSection from "./../components/HeroSection";
import WhyWebsiteHero from "../components/WhyWebsiteHeroDe";
import WhyWebsite from "../components/WhyWebsiteDe";
import { useRouter } from "../util/router.js";
import PopUp from "../components/popup"

function WhyWebsitePageDe(props) {
  const router = useRouter();

  return (
    <>
      <WhyWebsiteHero />
      
      {/* <ClientsSection color="light" size="normal" title="" subtitle="" /> */}
      <WhyWebsite />
      
      
      <PopUp />
    </>
  );
}

export default WhyWebsitePageDe;
