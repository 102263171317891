import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import SectionButton from "./SectionButton";
import "./HeroSection.scss";

function FullTimeJob(props) {
  return (
    <>
        <div className="project-details single-job version-two pos-r pt-150 pb-130 md-pt-200 md-pb-70 text-left">
				<div class="shape-wrapper" style={{zIndex:'0'}}>
					<svg class="img-shape shape-one">
					<path fill-rule="evenodd"  fill="rgb(255, 223, 204)"
					 d="M6.000,12.000 C9.314,12.000 12.000,9.314 12.000,6.000 C12.000,2.686 9.314,-0.000 6.000,-0.000 C2.686,-0.000 -0.000,2.686 -0.000,6.000 C-0.000,9.314 2.686,12.000 6.000,12.000 Z"/>
					</svg>
					<svg class="img-shape shape-two">
					<path fill-rule="evenodd"  fill="rgb(182, 255, 234)"
					 d="M6.000,12.000 C9.314,12.000 12.000,9.314 12.000,6.000 C12.000,2.686 9.314,-0.000 6.000,-0.000 C2.686,-0.000 -0.000,2.686 -0.000,6.000 C-0.000,9.314 2.686,12.000 6.000,12.000 Z"/>
					</svg>
					<svg class="img-shape shape-three">
					<path fill-rule="evenodd"  fill="rgb(181, 198, 255)"
					 d="M12.000,24.000 C18.627,24.000 24.000,18.627 24.000,12.000 C24.000,5.372 18.627,-0.000 12.000,-0.000 C5.372,-0.000 -0.000,5.372 -0.000,12.000 C-0.000,18.627 5.372,24.000 12.000,24.000 Z"/>
					</svg>
					<svg class="img-shape shape-four">
					<path fill-rule="evenodd"  fill="rgb(255, 156, 161)"
					 d="M7.500,15.000 C11.642,15.000 15.000,11.642 15.000,7.500 C15.000,3.358 11.642,-0.000 7.500,-0.000 C3.358,-0.000 -0.000,3.358 -0.000,7.500 C-0.000,11.642 3.358,15.000 7.500,15.000 Z"/>
					</svg>
					<svg class="img-shape shape-five">
					<path fill-rule="evenodd"  fill="rgb(178, 236, 255)"
					 d="M12.500,25.000 C19.403,25.000 25.000,19.403 25.000,12.500 C25.000,5.596 19.403,-0.000 12.500,-0.000 C5.596,-0.000 -0.000,5.596 -0.000,12.500 C-0.000,19.403 5.596,25.000 12.500,25.000 Z"/>
					</svg>
				</div>
				<div className="project-container">
					<div className="d-md-flex align-items-end justify-content-between pb-60">
						<div className="project-title-one">
							<span class="badge badge-lg badge-success">Vollzeit</span>
							<h3>Marketing & Vertrieb Vollzeit (m/f/d)</h3>
						</div>
						<div>
							<a href="/marketing-and-sales-full-time/apply" className=" theme-btn solid-button-one" >Jetzt bewerben</a>
						</div>
						{/* <ul className="social-icon-two sm-mt-40">
							<li><a href="#" className=" theme-btn solid-button-one" >Apply Now</a></li>
						</ul> */}
					</div>
					<div className="row">
						<div className="col-lg-12">
							<div className="info-text-list">
								{/* <p>We have been operating an IT system house in the Middle Nahe for over 15 years with our three branches in Bad Sobernheim, Kirn and Birkenfeld.</p> */}
								<p>Wir sind ein kreativer Web-Design-Service, der Technologie nutzt, um das Geschäft unserer Kunden zu steigern. Wir konzentrieren uns auf Qualität, Innovation und Schnelligkeit. Wir sind sehr stolz auf unsere Arbeitsethik.</p>
								{/* <p>Our goal is to install IT and telecommunications for people. Therefore, we act as a team on an equal footing with our customers.</p> */}
								{/* <p>Unser Ziel ist es, IT und Telekommunikation für Menschen zu installieren. Deshalb agieren wir als Team auf Augenhöhe mit unseren Kunden.</p> */}
							</div>
							{/* <h6 className="title-two md-pb-20">The Internet advertising <span>famous today.</span></h6> */}

							<div className="info-text-list mt-30">
								<h6>IHRE AUFGABEN</h6>
								<ul>
									<li> Entwicklung/Konzeption und Umsetzung von Strategien zur Lead-Generierung</li>
									<li> Analyse von Kundenbedürfnissen, Wettbewerb und Marktpotenzial</li>
									<li> Entwicklung von Vertriebskanälen und -strukturen</li>
									<li> Neukundengewinnung & Bestandskundenbetreuung</li>
									<li> Operativer Vertriebs- und Supportkanal</li>
									<li> Hohe Eigenmotivation, Kommunikationsfähigkeit und analytisches Denkvermögen</li>
									<li> Fließend in deutscher und englischer Sprache</li>
								</ul>
								
							</div>

							<div className="info-text-list mt-30">
								<h6>IHR PROFIL</h6>
								<ul>
									<li>Abgeschlossene Ausbildung im kaufmännischen Bereich</li>
									<li>Mindestens 5 Jahre Berufserfahrung im Vertrieb oder einer vergleichbaren Funktion</li>
									<li>Interesse an den Themen Webdesign, digitales Marketing, seo und neue Technologien</li>
									<li>Selbstständige, lösungsorientierte und strukturierte Arbeitsweise</li>
									<li>Unternehmerisches und kundenorientiertes Denken und Handeln</li>
									<li>Hohe Sozial- und Teamkompetenz</li>
									
								</ul>
							</div>

							<div className="info-text-list mt-30">
								<h6>Wir bieten</h6>
								<ul>
									<li>Eine interessante und abwechslungsreiche Tätigkeit in einem engagierten Team</li>
									<li>Selbstständiges Arbeiten und aktive Gestaltung der eigenen Arbeitsumgebung</li>
									<li>Flache Hierarchien und kurze Entscheidungswege</li>
								</ul>
								
							</div>

							<div className="mt-70">
								<a href="/marketing-and-sales-full-time/apply" className=" theme-btn solid-button-one" >Jetzt bewerben</a>
							</div>

						</div>
						{/* <div className="col-lg-5">
							<p>Here is also the chance that since you have article archive on the we  subscribers.</p>
							<p className="pt-35 md-pb-50">but they may find what’s they are looking browsing through  previous lorem quality io. lorem ipsum que dumy text. Remember not to bore your hard earned subscribers. Present them with a problem.</p>
						</div>
						<div className="col-lg-3">
							<div className="info-text-list">
								<h6>Date</h6>
								<p>23 July, 2018</p>
							</div> 
							<div className="info-text-list mt-30">
								<h6>Client Name</h6>
								<p>Mariona Adisson, USA</p>
							</div>
							<div className="info-text-list mt-30">
								<h6>Project Type</h6>
								<p>3D Design, Apartment</p>
							</div>
						</div> */}
					</div>
{/* 
					<div className="theme-pagination-two d-flex justify-content-between align-items-center mt-65 sm-mt-40">
						<a href="#" className="theme-pager prev">
							<span className="flaticon-back"></span>
							<i>Previous</i>
							<strong>Uber App Redesign.</strong>
						</a>
						<a href="#" className="theme-pager next">
							<span className="flaticon-next"></span>
							<i>Next</i>
							<strong>Rentloop Branding</strong>
						</a>
					</div> */}
				</div>
			</div>
		
    </>
  );
}

export default FullTimeJob;
