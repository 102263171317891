import React from "react";
// import HeroSection from "./../components/HeroSection";
import WhyWebsiteHero from "../components/WhyWebsiteHero";
import WhyWebsite from "../components/WhyWebsite";
import { useRouter } from "./../util/router.js";
import PopUp from "../components/popup"

function WhyWebsitePage(props) {
  const router = useRouter();

  return (
    <>
      <WhyWebsiteHero />
      
      {/* <ClientsSection color="light" size="normal" title="" subtitle="" /> */}
      <WhyWebsite />
      
      
      <PopUp />
    </>
  );
}

export default WhyWebsitePage;
