import React from "react";
import Section from "./Section";
import Newsletter from "./Newsletter";

function NewsletterSection(props) {
  return (
    <Section className="SectionComponent hero section is-block is-relative subscribe">
      
        <div class="newsletter-section newsletter-one pos-r">
    				<div class="shape-wrapper">
                <img src={require('../images/shape/17.svg')} alt="" class="shape-one img-shape" />
					      <img src={require('../images/shape/38.svg')} alt="" class="shape-two img-shape" />
					      <img src={require('../images/shape/4.svg')} alt="" class="shape-three img-shape" />
					      <img src={require('../images/shape/28.svg')} alt="" class="shape-four img-shape" /> 
					      <img src={require('../images/shape/9.svg')} alt="" class="shape-five img-shape" />
					      <img src={require('../images/shape/39.svg')} alt="" class="shape-six img-shape" />
					      <img src={require('../images/shape/40.svg')} alt="" class="shape-seven img-shape" />
				    </div>
				    <div class="container">
					      <div class="main-wrapper">
						        <div class="theme-title-one text-center pb-55">
                    <h2 class="main-title underline"><span>Subscribe Newsletter & get</span> <br/> <span>Latest News</span></h2>
						        </div>
                    <Newsletter 
                      buttonText={props.buttonText}
                      inputPlaceholder={props.inputPlaceholder}
                      subscribedMessage={props.subscribedMessage}
                      
                    />
						        {/* <form class="ajax-sub-form" method="post">
							          <input type="email" placeholder="Enter your email" id="subscription-email" />
							          <button><i class="flaticon-next"></i></button>
					              <label class="subscription-label" for="subscription-email"></label>
						        </form> */}
					      </div>
				    </div>
			</div> 
      {/* <div className="container">
        <div className="columns is-centered">
          <div className="column is-12 is-10-fullhd">
            <div className="columns is-vcentered">
              <div className="column is-half">
                <div className="title">{props.title}</div>
                <div className="subtitle">{props.subtitle}</div>
              </div>
              <div className="column is-half">
                <Newsletter
                  parentColor={props.color}
                  buttonText={props.buttonText}
                  inputPlaceholder={props.inputPlaceholder}
                  subscribedMessage={props.subscribedMessage}
                  size="medium"
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </Section>
  );
}

export default NewsletterSection;
