import React from "react";
import PartTimeJob from "../components/PartTimeJob";
// import CareerSection from "../components/CareerSection";

function CareerPartTime(props) {
  return (
    <>
    <PartTimeJob />
    {/* <CareerSection /> */}
    </>
  );
}

export default CareerPartTime;
