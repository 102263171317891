import React from "react";
import Navbar from "./../components/Navbar";
import IndexPage from "./index";
import IndexPageDe from "./index-de";
import AboutPage from "./about";
import FaqPage from "./faq";
import CareerPage from "./career";
import ApplyPage from "./apply";
import ApplyPageFull from "./applyfull";
import PricingPage from "./pricing";
import CareerFullTime from "./careerfulltime";
import CareerPartTime from "./careerparttime";
import ContactPage from "./contact";
import DashboardPage from "./dashboard";
import SigninPage from "./signin";
import SignupPage from "./signup";
import ForgotpassPage from "./forgotpass";
import WhyWebsitePage from "./whyyouneedwebsite";
import WhyWebsitePageDe from "./whyyouneedwebsitede";
import ChangepassPage from "./changepass";
import UnderConstructionPage from "./under-construction";
import UnderConstructionPageDe from "./under-construction-de";
import { Switch, Route, Router } from "./../util/router.js";
import Footer from "../components/Footer-old";
import "./../util/analytics.js";
import { ProvideAuth } from "./../util/auth.js";
function App(props) {
  return (
    <ProvideAuth>
      <Router>
        <>
          <Navbar
            color="white"
            spaced={true}
            logo={require('../images/embtel.png')}
          />
          <Switch>
            {/* <Route exact path="/" component={UnderConstructionPageDe} /> */}
            {/* <Route exact path="/en" component={UnderConstructionPage} /> */}
            {/* <Route exact path="/de" component={UnderConstructionPageDe} /> */}
            <Route exact path="/" component={IndexPage} />
            <Route exact path="/de" component={IndexPageDe} />
            <Route exact path="/about" component={AboutPage} />
            <Route exact path="/marketing-and-sales-part-time/apply" component={ApplyPage} />
            <Route exact path="/marketing-and-sales-full-time/apply" component={ApplyPageFull} />
            <Route exact path="/career" component={CareerPage} />
            
            <Route exact path="/marketing-and-sales-full-time" component={CareerFullTime} />
            <Route exact path="/marketing-and-sales-part-time" component={CareerPartTime} />
            <Route exact path="/faq" component={FaqPage} />
            <Route exact path="/pricing" component={PricingPage} />
            <Route exact path="/contact" component={ContactPage} />
            <Route exact path="/dashboard" component={DashboardPage} />
            <Route exact path="/signin" component={SigninPage} />
            <Route exact path="/signup" component={SignupPage} />
            <Route exact path="/why-you-need-website" component={WhyWebsitePage} />
            <Route exact path="/de/why-you-need-website" component={WhyWebsitePageDe} />
            <Route exact path="/forgotpass" component={ForgotpassPage} />
            <Route exact path="/changepass" component={ChangepassPage} />
            <Route
              component={({ location }) => {
                return (
                  <div
                    style={{
                      padding: "50px",
                      width: "100%",
                      textAlign: "center"
                    }}
                  >
                    The page <code>{location.pathname}</code> could not be
                    found.
                  </div>
                );
              }}
            />
          </Switch>
          <Footer
            color="light"
            size="normal"
            logo={require('../images/embtel.png')}
            
          />
        </>
      </Router>
    </ProvideAuth>
  );
}
export default App;