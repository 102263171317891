import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Features from "./CoreFeatures";

function CoreFeature(props) {
  return (
    <Section id="features" className="SectionComponent hero section is-block is-relative core-features">
        <div className="our-core-feature style-one pb-30 md-pt-80 pos-r">
				    <div className="shape-wrapper">
					      <img src="https://embtel.ca/desievent/images/shape/14.svg" alt="" className="shape-one img-shape" />
                <img src="https://embtel.ca/desievent/images/shape/4.svg" alt="" className="shape-two img-shape" />
                <img src="https://embtel.ca/desievent/images/shape/5.svg" alt="" className="shape-three img-shape" />
                <img src="https://embtel.ca/desievent/images/shape/17.svg" alt="" className="shape-four img-shape" />
                <img src="https://embtel.ca/desievent/images/shape/18.svg" alt="" className="shape-five img-shape" />
                <img src="https://embtel.ca/desievent/images/shape/19.svg" alt="" className="shape-six img-shape" />
				    </div>
				    <div className="container">
					      <div className="row">
						        <div className="col-lg-5 order-lg-last mt-225 md-m0">
							          <div className="theme-title-one">
								            <div className="upper-title mb-5">Features</div>
								                <h2 className="main-title underline"><span>Our Core Features</span></h2>
							              </div>
						              	<p className="pt-40 pb-50">We are an Agile company and love working with Scrum. We work in multiple iterations and encourage using the lean startup principles, by first creating an MVP.</p>
							              <a href="#get-quote" className="theme-btn line-button-one">Get Free Quote <i className="fa fa-angle-right" aria-hidden="true"></i></a>
						            </div>
						            <div className="col-lg-7 order-lg-first">
							              <div className="row feature-block-wrapper">
								                <div className="col-md-6">
									                  <div className="feature-block-one hover-state mt-200 sm-mt-80 js-tilt">
										                    <div className="icon icon-one flaticon-web tran3s"></div>
										                    <h5 className="pt-30 tran3s title">Define requirements</h5>
										                    <p className="tran3s">Enlist the set of wanted functionalities as specifications and/or user stories.</p>
									                  </div>
								                </div>  
								                <div className="col-md-6">
									                  <div className="feature-block-one hover-state mb-40 mt-40 js-tilt">
										                    <div className="icon icon-two flaticon-menu-2 tran3s"></div>
										                    <h5 className="pt-30 tran3s title">UI/UX Analysis.</h5>
										                    <p className="tran3s">Add colors and styling to the wireframe. Apply branding, interface and motion design to obtain an interactive prototype.</p>
									                  </div>
									
									                  <div className="feature-block-one hover-state js-tilt">
									                      <div className="icon icon-three flaticon-login tran3s"></div>
										                    <h5 className="pt-30 tran3s title">Wireframing</h5>
										                    <p className="tran3s">Obtain a graphic block-scheme showing app screens elements, content, and navigation.</p>
									              </div>
								            </div>
							          </div>
						        </div>
					      </div>
				    </div>
			</div>
      {/* <div className="container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          centered={true}
          size={3}
        />
        <Features
          items={[
            {
              title: "Explore",
              description:
                "Integer ornare neque mauris, ac vulputate lacus venenatis et. Pellentesque ut ultrices purus.",
              image: "https://uploads.divjoy.com/undraw-mind_map_cwng.svg"
            },
            {
              title: "Explore",
              description:
                "Integer ornare neque mauris, ac vulputate lacus venenatis et. Pellentesque ut ultrices purus.",
              image:
                "https://uploads.divjoy.com/undraw-personal_settings_kihd.svg"
            },
            {
              title: "Explore",
              description:
                "Integer ornare neque mauris, ac vulputate lacus venenatis et. Pellentesque ut ultrices purus.",
              image: "https://uploads.divjoy.com/undraw-having_fun_iais.svg"
            },
            {
              title: "Explore",
              description:
                "Integer ornare neque mauris, ac vulputate lacus venenatis et. Pellentesque ut ultrices purus.",
              image: "https://uploads.divjoy.com/undraw-balloons_vxx5.svg"
            }
          ]}
        />
      </div> */}
    </Section>
  );
}

export default CoreFeature;
