import React from "react";
import HeroCareer from "../components/HeroCareer";
import CareerSection from "../components/CareerSection";

function CareerPage(props) {
  return (
    <>
    <HeroCareer />
    <CareerSection />
    </>
  );
}

export default CareerPage;
