function subscribe(data) {
  return fetch("api/newsletter", {
    method: "POST",
    headers: {
      "Content-Type": "application/json", 
      'Accept': 'application/json'
    },
    body: JSON.stringify(data)
  }).then(r => r.json());
}

export default { subscribe };

// async function subscribe(data) {
//   const r = await fetch("api/newsletter", {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json"
//     },
//     body: JSON.stringify(data)
//   });
//   return await r.json();
// }

// export default { subscribe };
