import React from "react";
// import HeroSection from "./../components/HeroSection";
import SliderSection from "../components/MainSliderDe";
import CoreFeature from "../components/CoreFeaturesSectionDe";
import AboutUs from "../components/AboutUsSectionDe";
import OurServices from "../components/OurServicesSectionDe";
import OurTestimonials from "../components/TestimonialSectionDe";
import OurProjects from "../components/ProjectsSectionDe";
import Consultation from "../components/ConsultationSectionDe";
import OurTeam from "../components/OurTeamDe";

import NewsletterSection from "../components/NewsletterSection";

import { useRouter } from "../util/router.js";
import PopUpDe from "../components/popup-de"

function IndexPageDe(props) {
  const router = useRouter();

  return (
    <>
      <SliderSection />
      {/* <HeroSection
        color="white"
        size="medium"
        title="Your landing page title here"
        subtitle="This landing page is perfect for showing off your awesome product and driving people to sign up for a paid plan."
        buttonText="Get Started"
        image="https://uploads.divjoy.com/undraw-japan_ubgk.svg"
        buttonOnClick={() => {
          router.push("/pricing");
        }}
      /> */}
      {/* <ClientsSection color="light" size="normal" title="" subtitle="" /> */}
      <CoreFeature />
      {/* <FeaturesSection
        color="white"
        size="medium"
        title="Features"
        subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud."
      /> */}
      <AboutUs />
      {/* <TestimonialsSection
        color="light"
        size="medium"
        title="Here's what people are saying"
        subtitle=""
      /> */}
      <OurServices />
      <OurProjects />
      <OurTestimonials />
      <OurTeam />
      <Consultation />
      <NewsletterSection
        color="white"
        size="medium"
        title="Stay in the know"
        subtitle="Receive our latest articles and feature updates"
        buttonText="Subscribe"
        inputPlaceholder="Enter your email"
        subscribedMessage="Thank You for Subscribing. We'll get back to you soon."
      />
      <PopUpDe />
    </>
  );
}

export default IndexPageDe;
