import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import SectionButton from "./SectionButton";
import "./HeroSection.scss";

function ApplyNow(props) {
  return (
    <>
		<div className="container">
			<div className="row justify-content-center">
				<div className="col-md-12 text-center">
					<iframe style={{width:'810px', height:'800px'}} src='https://jobs.embtelsolutions.in/recruit/WebFormServeServlet?rid=1fc6b0df542ce248d3edbb93d7eee48df9d8d7cb2746bce264b1c070f7c6b4a1gid1a8cdb4ad944b0de7e39817273f771a3'></iframe>
				</div>
				<div className="alert alert-info">
					Bei Embtel Solutions verpflichten wir uns, die Sicherheit und den Schutz der von uns verarbeiteten personenbezogenen Daten zu gewährleisten. Wir haben Datenschutzteams, die sich der laufenden Überprüfung und Wachsamkeit aller Angelegenheiten innerhalb des Geltungsbereichs der GDPR verschrieben haben, so dass ein Prozess der kontinuierlichen Bewertung, des Risikomanagements und der Verbesserung in unserer Organisation eingebettet ist.
				</div>
			</div>
		</div>
		
    </>
  );
}

export default ApplyNow;
