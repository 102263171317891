import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";


function OurTeam(props) {
  return (
    <Section id="our-team">
		<div class="text-inner-banner-one pos-r pt-0">
				<div class="shape-wrapper">
					<svg class="img-shape shape-one">
					<path fill-rule="evenodd"  fill="rgb(255, 223, 204)"
					 d="M6.000,12.000 C9.314,12.000 12.000,9.314 12.000,6.000 C12.000,2.686 9.314,-0.000 6.000,-0.000 C2.686,-0.000 -0.000,2.686 -0.000,6.000 C-0.000,9.314 2.686,12.000 6.000,12.000 Z"/>
					</svg>
					<svg class="img-shape shape-two">
					<path fill-rule="evenodd"  fill="rgb(182, 255, 234)"
					 d="M6.000,12.000 C9.314,12.000 12.000,9.314 12.000,6.000 C12.000,2.686 9.314,-0.000 6.000,-0.000 C2.686,-0.000 -0.000,2.686 -0.000,6.000 C-0.000,9.314 2.686,12.000 6.000,12.000 Z"/>
					</svg>
					<svg class="img-shape shape-three">
					<path fill-rule="evenodd"  fill="rgb(181, 198, 255)"
					 d="M12.000,24.000 C18.627,24.000 24.000,18.627 24.000,12.000 C24.000,5.372 18.627,-0.000 12.000,-0.000 C5.372,-0.000 -0.000,5.372 -0.000,12.000 C-0.000,18.627 5.372,24.000 12.000,24.000 Z"/>
					</svg>
					<svg class="img-shape shape-four">
					<path fill-rule="evenodd"  fill="rgb(255, 156, 161)"
					 d="M7.500,15.000 C11.642,15.000 15.000,11.642 15.000,7.500 C15.000,3.358 11.642,-0.000 7.500,-0.000 C3.358,-0.000 -0.000,3.358 -0.000,7.500 C-0.000,11.642 3.358,15.000 7.500,15.000 Z"/>
					</svg>
					<svg class="img-shape shape-five">
					<path fill-rule="evenodd"  fill="rgb(178, 236, 255)"
					 d="M12.500,25.000 C19.403,25.000 25.000,19.403 25.000,12.500 C25.000,5.596 19.403,-0.000 12.500,-0.000 C5.596,-0.000 -0.000,5.596 -0.000,12.500 C-0.000,19.403 5.596,25.000 12.500,25.000 Z"/>
					</svg>
				</div>
				<div class="container">
					<p>Team</p>
					<h2 class="pt-30 pb-15">Wir haben ein Expertenteam.</h2>
					<p class="sub-heading">Von einem Team leidenschaftlicher Kreativer, die Seite an Seite mit unseren Partnern arbeiten, um ansprechende digitale und physische Kampagnen zu liefern.</p>
				</div>
			</div>
			<div class="team-standard our-team pb-200 md-pb-80">
				<div class="container">
					<div class="row justify-content-center">
					<div class="col-lg-4 col-md-6">
							<div class="single-team-member">
								<div class="wrapper pos-r">
									<div class="img-box"><img src={require('../images/team/fenilparekh.jpeg')} alt="" /></div>
									<div class="info-meta">
										<h6 class="name">Fenil Parekh</h6>
										<span>Growth Hacker</span>
									</div>
								</div>
								<div class="hover-content">
									<ul>
										<li><a href="#"><i class="fab fa-facebook-square" aria-hidden="true"></i></a></li>
										<li><a href="#"><i class="fab fa-twitter" aria-hidden="true"></i></a></li>
										<li><a href="#"><i class="fab fa-linkedin" aria-hidden="true"></i></a></li>
									</ul>
								</div>
							</div>
						</div> 
						<div class="col-lg-4 col-md-6">
							<div class="single-team-member">
								<div class="wrapper pos-r">
									<div class="img-box"><img src={require('../images/team/csilla.jpg')}  alt="" /></div>
									<div class="info-meta">
										<h6 class="name">Csilla Rostas</h6>
										<span>Marketingleiter</span>
									</div>
								</div>
								<div class="hover-content">
									<ul>
										<li><a href="#"><i class="fab fa-facebook-square" aria-hidden="true"></i></a></li>
										<li><a href="#"><i class="fab fa-twitter" aria-hidden="true"></i></a></li>
										<li><a href="#"><i class="fab fa-linkedin" aria-hidden="true"></i></a></li>
									</ul>
								</div> 
							</div>
						</div>
					</div>
				</div>
			</div>
    </Section>
  );
}

export default OurTeam;
