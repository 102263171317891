import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";


function WhyWebsite(props) {
  return (
    <Section id="why-website" className="SectionComponent hero section is-block is-relative why-need-website">
        <div className="our-service-one pos-r">
				    <div className="shape-wrapper">
                <img src="https://embtel.ca/desievent/images/shape/26.svg" alt="" className="shape-one img-shape" />
                <img src="https://embtel.ca/desievent/images/shape/28.svg" alt="" className="shape-two img-shape" />
                <img src="https://embtel.ca/desievent/images/shape/13.svg" alt="" className="shape-three img-shape" />
                <img src="https://embtel.ca/desievent/images/shape/29.svg" alt="" className="shape-four img-shape" />
                <img src="https://embtel.ca/desievent/images/shape/9.svg" alt="" className="shape-five img-shape" />
                <img src="https://embtel.ca/desievent/images/shape/30.svg" alt="" className="shape-six img-shape" />
                <img src="https://embtel.ca/desievent/images/shape/27.svg" alt="" className="shape-seven img-shape" />
                <img src="https://embtel.ca/desievent/images/shape/31.svg" alt="" className="shape-eight img-shape" />
                <img src="https://embtel.ca/desievent/images/shape/32.svg" alt="" className="shape-nine img-shape" />
				    </div>
		{/* <div class="help-articles"> */}
				<div class="container">
					<div class="row">
						<div className="col-md-2"></div>
						<div className="col-md-8">
							<div className="para">
								<h5 class="mb-20">A website is always accessible.</h5>
								<p>Better accessibility by the progressive technologization it is important to draw a meaningful use from the Zeitgeist. Back then we had phone books, right? Today everything is much more subtle and faster.</p>
								<p>Today there are search engines where most things are just a click away without much effort. Above all, it must be easy and quick to find what you are looking for. </p>
								<p>The Internet has now made this possible and offers more than just a contact possibility, but also provides information. Nowadays it is relatively fast attainable to pull desired information from the net. Theoretically there is an answer for almost everything, if not for everything</p>
							</div>
						</div>
						<div className="col-md-2"></div>
						<div className="col-md-2"></div>
						<div className="col-md-8">
							<div className="para">
								<h5 class="mb-20">Customers can be kept informed.</h5>
								<p>To be or not to be, that is the question here! Nowadays your customers also want to have something for the eye and therefore get a simple service of a web page guaranteed. </p>
								<p>In these fastpaced times it is also about convenience and at the same time speed. Sometimes a performance can be seen as an egg-laying woolly pig. </p>
								<p>Your customers prefer to have yesterday's desired contact.</p>
							</div>
						</div>
						<div className="col-md-2"></div>
						<div className="col-md-2"></div>
						<div className="col-md-8">
							<div className="para">
								<h5 class="mb-20">Platform to show your work/services.</h5>
								<p>A web page offers very effectively preliminary information. In contrast to the good old telephone book, an easy to use and functionally helpful presence in the worldwide network greatly increases the possibility that the attention to your company and also indirect recommendations from visitors is passed on. </p>
								<p>The more simple and tidy a website looks, the faster new contacts can be made.</p>
							</div>
						</div>
						<div className="col-md-2"></div>
						<div className="col-md-2"></div>
						<div className="col-md-8">
							<div className="para">
							<h5 class="mb-20">Accessibility.</h5>
									<p>Depending upon enterprise structure and depending upon offered service you save additional costs for real estates (office premises), since you can work comfortably from at home and keep any storage questions by Just-In Time methods very small. </p>
									<p>All other things could be redirected through strategic positioning, reducing the fixed costs for your business. Just ask us what this might look like for your business</p>
							</div>
						</div>
						<div className="col-md-2"></div>
						<div className="col-md-2"></div>
						<div className="col-md-8">
							<div className="para">
								<h5 class="mb-20">A website can improve customer service.</h5>
								<p>If you have a product, customers can place an order at any time of the day. This will boost your sales speed and recommendations immensely</p>
							</div>
						</div>
						<div className="col-md-2"></div>
						<div className="col-md-2"></div>
						<div className="col-md-8">
							<div className="para">
								<h5 class="mb-20">Get ahead of your competitors.</h5>
								<p>The latest technology used correctly and consciously is a tool. As with any tool, knowledge about how it works and how it is used must be available</p>
							</div>
						</div>
						<div className="col-md-2"></div>
						<div className="col-md-2"></div>
						<div className="col-md-8">
							<div className="para">
								<h5 class="mb-20">It saves you money in the long term.</h5>
								<p>By additional search machine optimization you save much money for additional advertisement, because their customers find you on the basis carefully selected search terms.</p>							
							</div>
						</div>
						<div className="col-md-2"></div>
						<div className="col-md-2"></div>
						<div className="col-md-8">
							<div className="para">
								<h5 class="mb-20">Your business will gain credibility.</h5>
								<p>By strategically positioning yourself in your market area, you will achieve a higher level of feedback than if you were to spend your budget physically in mostly costly advertising. </p>
								<p>You have more money to reinvest in effective current technologies.</p>
							</div>
						</div>
						<div className="col-md-2"></div>
						<div className="col-md-2"></div>
						<div className="col-md-8">
							<div className="para">
								<h5 class="mb-20">Your business will gain credibility.</h5>
								<p>By strategically positioning yourself in your market area, you will achieve a higher level of feedback than if you were to spend your budget physically in mostly costly advertising. </p>
								<p>You have more money to reinvest in effective current technologies.</p>
							</div>
						</div>
						<div className="col-md-2"></div>
						<div className="col-md-2"></div>
						<div className="col-md-8">
							<div className="para">
								<h5 class="mb-20">Minimum viable product.</h5>
								<p>MVP (minimum viable product) gives you tailor-made concepts if you need a special app in your market segment, for example, which we then program for you and expand upon after publication until it can be incorporated into your corporate philosophy in a truly appropriate and ever more effective manner</p>
							</div>
						</div>
						<div className="col-md-2"></div>
						
						{/* <div class="col-lg-7 col-md-7 col-12">
						<div class="feature-block-one hover-state mb-40 js-tilt">
								
									<h5 class="mb-20">A website is always accessible.</h5>
									<p>Better accessibility by the progressive technologization it is important to draw a meaningful use from the Zeitgeist. Back then we had phone books, right? Today everything is much more subtle and faster. Today there are search engines where most things are just a click away without much effort. Above all, it must be easy and quick to find what you are looking for. The Internet has now made this possible and offers more than just a contact possibility, but also provides information. Nowadays it is relatively fast attainable to pull desired information from the net. Theoretically there is an answer for almost everything, if not for everything</p>
								
							</div> 
						</div>
						<div class="col-lg-5 col-md-5 col-12">
						<div class="feature-block-one hover-state mb-40 js-tilt">
								
									<h5 class="mb-20">Customers can be kept informed.</h5>
									<p>To be or not to be, that is the question here! Nowadays your customers also want to have something for the eye and therefore get a simple service of a web page guaranteed. In these fastpaced times it is also about convenience and at the same time speed. Sometimes a performance can be seen as an egg-laying woolly pig. Your customers prefer to have yesterday's desired contact.</p>
								
							</div>
						</div> 
						<div class="col-lg-6 col-md-6 col-12">
						<div class="feature-block-one hover-state mb-40 js-tilt">
								
									<h5 class="mb-20">Platform to show your work/services.</h5>
									<p>A web page offers very effectively preliminary information. In contrast to the good old telephone book, an easy to use and functionally helpful presence in the worldwide network greatly increases the possibility that the attention to your company and also indirect recommendations from visitors is passed on. The more simple and tidy a website looks, the faster new contacts can be made.</p>
								
							</div>
						</div>
						<div class="col-lg-6 col-md-6 col-12">
						<div class="feature-block-one hover-state mb-40 js-tilt">
								
									<h5 class="mb-20">Accessibility.</h5>
									<p>Depending upon enterprise structure and depending upon offered service you save additional costs for real estates (office premises), since you can work comfortably from at home and keep any storage questions by Just-In Time methods very small. All other things could be redirected through strategic positioning, reducing the fixed costs for your business. Just ask us what this might look like for your business</p>
								
							</div> 
						</div> 
						<div class="col-lg-4 col-md-6 col-12">
						<div class="feature-block-one hover-state mb-40 js-tilt">
								<a href="#">
									<h5 class="mb-20">A website can improve customer service.</h5>
									<p>If you have a product, customers can place an order at any time of the day. This will boost your sales speed and recommendations immensely</p>
								</a>
							</div>
						</div> 
						<div class="col-lg-4 col-md-6 col-12">
						<div class="feature-block-one hover-state mb-40 js-tilt">
								
									<h5 class="mb-20">Get ahead of your competitors.</h5>
									<p>The latest technology used correctly and consciously is a tool. As with any tool, knowledge about how it works and how it is used must be available</p>
								
							</div> 
						</div>
						<div class="col-lg-4 col-md-6 col-12">
						<div class="feature-block-one hover-state mb-40 js-tilt">
								
									<h5 class="mb-20">It saves you money in the long term.</h5>
									<p>By additional search machine optimization you save much money for additional advertisement, because their customers find you on the basis carefully selected search terms.</p>
								
							</div> 
						</div> 
						
						<div class="col-lg-6 col-md-6 col-12">
						<div class="feature-block-one hover-state mb-40 js-tilt">
								
									<h5 class="mb-20">Your business will gain credibility.</h5>
									<p>By strategically positioning yourself in your market area, you will achieve a higher level of feedback than if you were to spend your budget physically in mostly costly advertising. You have more money to reinvest in effective current technologies.</p>
								
							</div>
						</div> 
						<div class="col-lg-6 col-md-6 col-12">
						<div class="feature-block-one hover-state mb-40 js-tilt">
								
									<h5 class="mb-20">Minimum viable product.</h5>
									<p>MVP (minimum viable product) gives you tailor-made concepts if you need a special app in your market segment, for example, which we then program for you and expand upon after publication until it can be incorporated into your corporate philosophy in a truly appropriate and ever more effective manner</p>
								
							</div>
						</div> */}
					</div> 

					
				</div> 
			</div>  
      
    </Section>
  );
}

export default WhyWebsite;
