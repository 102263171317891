import React from "react";
import FullTimeJob from "../components/FullTimeJob";
// import CareerSection from "../components/CareerSection";

function CareerFullTime(props) {
  return (
    <>
    <FullTimeJob />
    {/* <CareerSection /> */}
    </>
  );
}

export default CareerFullTime;
