import React from "react";
import Section from "./Section";


function PopUp(props) {
  return (
    <Section id="popup" className="SectionComponent hero section is-block is-relative pop-up">
        <div class="modal fade ModalnewsletterSimple show" id="Modalnewsletter">
  			<div class="modal-dialog modal-discount">
    			<div class="modal-content ">
				<div className="close_icon">
						  <i className="fa fa-times dismiss-text"></i>
					  </div>
      				<div class="form-default">
        				<div class="tt-layout-center">
          					<div class="tt-promo-title">
            					<div class="text-simple-01">Das Angebot endet am 31. Dezember</div>
            					<div class="text-simple-02">SUPER<br/>CHRISTMAS</div>
            					{/* <div class="text-simple-03">WEEKEND SALE</div> */}
            					<div class="text-simple-04 pt-10"><span className="price-tag">40%</span> OFF</div>
            					<div class="text-simple-05 pt-10">Auf Web Design</div>
            					<a href="" class="theme-btn solid-button-one mt-40">JETZT ANRUFEN</a>
								<div className="dismiss-text pt-30">Nein Danke!</div>
          					</div>
        				</div>
      				</div>
    			</div>
  			</div>
		</div>
    </Section>
  );
}

export default PopUp;
