import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";


function AboutUs(props) {
  return (
    <Section id="about" className="SectionComponent hero section is-block is-relative about-us">
        <div className="about-us-block-one pt-200 pb-30 md-pt-130 pos-r">
				    <div className="shape-wrapper">
							<img src={require('../images/shape/21.svg')} alt="" className="shape-one img-shape" />
					      <img src={require('../images/shape/23.svg')} alt="" className="shape-two img-shape" />
					      <img src={require('../images/shape/24.svg')} alt="" className="shape-three img-shape" />
					      <img src={require('../images/shape/17.svg')} alt="" className="shape-four img-shape" />
					      <img src={require('../images/shape/9.svg')} alt="" className="shape-five img-shape" />
					      <img src={require('../images/shape/25.svg')} alt="" className="shape-six img-shape" />
				    </div>
				    <div className="inner-wrapper pos-r">
					      <div className="container">
						        <div className="row">
							          <div className="col-lg-6">
								            <div className="theme-title-one">
									              <div className="upper-title mb-5">ÜBER UNS</div>
									              <h2 className="main-title underline"><span>Wir lieben,</span> <span>was wir tun!</span></h2>
								            </div>
								            <p className="pt-40 bold-text pb-40">Wir sind ein kreativer Webdesign-Dienstleister, der Technologie einsetzt, um das Geschäft unserer Kunden auszubauen. Wir konzentrieren uns auf Qualität, Innovation und Schnelligkeit. Wir sind sehr stolz auf unsere Arbeitsethik und Moral. </p>
								            <p className="bold-text pb-40">Embtel Solutions hat es sich zur Aufgabe gemacht, Unternehmen bei der Erreichung ihrer Ziele durch eine signifikante Präsenz auf allen digitalen Plattformen zu unterstützen und ihnen zu helfen, zu wachsen.</p>
								            <a href="#get-quote" className="theme-btn solid-button-one">Kontaktiere uns  <i className="fa fa-angle-right" aria-hidden="true"></i></a>
							          </div>
						        </div>
					      </div>
					      <div className="path-shape-holder">
						  		<img src={require('../images/shape/16.svg')} alt="" />
						        <div className="image-box">
                        <svg width="666" height="578">
                            <g clip-path="url(#path-shape-one)">
								<image width="666" height="578" href={require('../images/home/1.jpg')} className="image-shape">
                                </image>
                            </g>
                        </svg>
						        </div>
					      </div>
				    </div>
			  </div> 
        <div id="path-shape-wrapper">
				<div>
					<svg height="0" width="0">
						<defs>
						    <clipPath id="path-shape-one">
						     	<path fill-rule="evenodd"  fill="url(#PSgrad_0)"
 d="M205.167,7.943 C196.497,5.430 187.599,3.410 178.431,1.968 C128.511,-5.887 71.772,9.587 43.924,51.651 C19.381,88.722 21.921,137.016 28.728,180.915 C36.372,230.212 42.902,274.488 30.686,323.548 C22.073,358.142 10.514,392.042 3.990,427.142 C-9.427,499.346 8.748,558.050 93.045,555.629 C129.787,554.575 165.485,540.991 199.389,527.944 C222.170,519.186 249.687,504.720 274.747,508.015 C298.508,511.143 314.810,529.991 331.946,542.158 C360.484,562.425 393.173,576.700 432.090,577.905 C544.608,581.383 647.199,488.167 663.495,395.667 C683.050,284.674 584.750,212.456 485.909,166.645 C447.031,148.625 408.266,133.864 373.136,108.990 C319.781,71.209 268.682,26.354 205.167,7.943 "/>
						    </clipPath>
						</defs>
					</svg>
				</div>
			</div>
    </Section>
  );
}

export default AboutUs;
