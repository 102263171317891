import React from "react";
// import React, { Component } from "react";
import Section from "./Section";
// import cookie from "react-cookies";

// import { googleTranslate } from "./../util/googleTranslate";
import { Link } from "../util/router.js";
import "./Footer.scss";

function Footer(props) {
  // state = {
  //   languageCodes: [],
  //   language: cookie.load("language") ? cookie.load("language") : "en",
  //   question: cookie.load("question")
  //     ? cookie.load("question")
  //     : "What language do you prefer to read with?"
  // };

  // componentDidMount() {
  //   // load all of the language options from Google Translate to your app state

  //   googleTranslate.getSupportedLanguages("en", function(err, languageCodes) {
  //     getLanguageCodes(languageCodes); // use a callback function to setState
  //   });

  //   const getLanguageCodes = languageCodes => {
  //     this.setState({ languageCodes });
  //   };
  // }
  // const { languageCodes, language, question } = this.state;
  return (
    <Section  size={props.size}>
      <div className="FooterComponent__container container">
        <div className="brand left">
          <Link to="/">
            <img src={props.logo} alt="Logo" />
          </Link>
        </div>
        
        <div className="links right eng">
          {/* <a href="#about">About</a>
          <a href="#features">Features</a>
          <a href="#services">Services</a>
          <a href="/why-you-need-website">Why You Need Website?</a> */}
          <a href="/" title="English" class="gflag flag-eng nturl" ><img src={require('../images/united-states.png')} height="20" width="20" alt="English" /></a><a href="/de" title="German" class="gflag flag-gr nturl"  ><img src={require('../images/germany.png')} height="20" width="20" alt="German" /></a>
          
        </div>
        <div className="links right ger">
          {/* <a href="#about">Über </a>
          <a href="#features">Eigenschaften </a>
          <a href="#services">Leistungen </a>
          <a href="/de/why-you-need-website">Warum Sie Website?</a> */}
          <a href="/" title="English" class="gflag flag-eng nturl" ><img src={require('../images/united-states.png')} height="20" width="20" alt="English" /></a><a href="/de" title="German" class="gflag flag-gr nturl"  ><img src={require('../images/germany.png')} height="20" width="20" alt="German" /></a>
          
        </div>
        <div className="social right">
          <a
            href="https://twitter.com/embtelsolutions"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="icon">
              <i className="fab fa-twitter" />
            </span>
          </a>
          <a
            href="https://www.facebook.com/embtelsolutionsinc/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="icon">
              <i className="fab fa-facebook-f" />
            </span>
          </a>
          <a
            href="https://www.instagram.com/embtelsolutions/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="icon">
              <i className="fab fa-instagram" />
            </span>
          </a>
          <a
            href="https://www.linkedin.com/company/embtel-solutions-inc-fremont/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="icon">
              <i className="fab fa-linkedin" />
            </span>
          </a>
          <a
            href="https://www.yelp.com/biz/embtel-solutions-fremont-3"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="icon">
              <i className="fab fa-yelp" />
            </span>
          </a>
          <a
            href="https://dribbble.com/embtelsolutions"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="icon">
              <i className="fab fa-dribbble" />
            </span>
          </a>
        </div>
        {/* <div className="copyright left">{props.copyright}</div> */}
        <div className="copyright left ger-copy">© 2021 Embtel Solutions, Inc. Alle Rechte vorbehalten.</div>
        <div className="copyright left eng-copy">© 2021 Embtel Solutions, Inc. All Rights Reserved.</div>
      </div>
    </Section>
  );
  // changeHandler = language => {
  //   let { question } = this.state;
  //   let cookieLanguage = cookie.load("language");
  //   let transQuestion = "";

  //   const translating = transQuestion => {
  //     if (question !== transQuestion) {
  //       this.setState({ question: transQuestion });
  //       cookie.save("question", transQuestion, { path: "/" });
  //     }
  //   };

  //   // translate the question when selecting a different language
  //   if (language !== cookieLanguage) {
  //     googleTranslate.translate(question, language, function(err, translation) {
  //       transQuestion = translation.translatedText;
  //       translating(transQuestion);
  //     });
  //   }

  //   this.setState({ language });
  //   cookie.save("language", language, { path: "/" });
  // };
  
}

export default Footer;
