import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import SectionButton from "./SectionButton";
import "./HeroSection.scss";


function SliderSection(props) {
 
  return (
    <Section className="SectionComponent hero section is-block is-relative home-slider">
      <div className="rogan-hero-section rogan-hero-one pt-300 pb-350 md-pt-200 md-pb-130 pos-r">
				  <div className="shape-wrapper">
					    <img src={require('../images/shape/1-svg-1.svg')} alt="" className="shape-one wow fadeInRight animated" data-wow-duration="2s" />
					    <div className="main-illustration">
						{/* <img src="images/shape/banner-shape-1.svg" alt="" className="b-shape-1 wow fadeInDown animated" data-wow-duration="2s" data-wow-delay="1.7s">
						<img src="images/shape/banner-shape-2.svg" alt="" className="b-shape-2 wow zoomIn animated" data-wow-duration="2s" data-wow-delay="1.7s"> */}
						      <iframe src="https://embtel.us/images/demo.html" className="wow zoomIn animated" data-wow-duration="2s" data-wow-delay="0.9s"></iframe>
					    </div>
				    	<img src={require('../images/shape/2.svg')} alt="" className="line-shape-one img-shape wow fadeInRight animated" data-wow-duration="3s"/>
              <img src={require('../images/shape/3.svg')} alt="" className="line-shape-two img-shape wow fadeInLeft animated" data-wow-duration="3s"/>
              <img src={require('../images/shape/15.svg')} alt="" className="light-lamp img-shape wow fadeInDown animated" data-wow-duration="2s" data-wow-delay="1.7s"/>
              <img src={require('../images/shape/4.svg')} alt="" className="shape-two img-shape"/>
              <img src={require('../images/shape/5.svg')} alt="" className="shape-three img-shape"/>
              <img src={require('../images/shape/6.svg')} alt="" className="shape-four img-shape"/>
              <img src={require('../images/shape/7.svg')} alt="" className="shape-five img-shape"/>
              <img src={require('../images/shape/8.svg')} alt="" className="shape-six img-shape"/>
              <img src={require('../images/shape/9.svg')} alt="" className="shape-seven img-shape"/>
              <img src={require('../images/shape/10.svg')} alt="" className="shape-eight img-shape"/>
              <img src={require('../images/shape/11.svg')} alt="" className="shape-nine img-shape"/>
              <img src={require('../images/shape/12.svg')} alt="" className="shape-ten img-shape"/>
              <img src={require('../images/shape/13.svg')} alt="" className="shape-eleven img-shape"/>
              <img src={require('../images/shape/14.svg')} alt="" className="shape-twelve img-shape"/>
			  	</div>
				  <div className="container">
					    <div className="main-wrapper pos-r">
						      <div className="banner-upper-heading wow fadeInDown animated">Welcome to <span>Embtel Solutions.</span></div>
						      <h1 className="banner-main-title underline pt-15 pb-35 md-pt-10 md-pb-30 wow fadeInUp animated" data-wow-delay="0.4s"><span>We Are A Creative</span> <br/> <span>Digital Agency.</span></h1>
						      <p className="banner-sub-title pb-35 md-pb-30 wow fadeInUp animated" data-wow-delay="0.9s">Focusing on connecting customers with companies<br/>through ground-breaking digital marketing<br/>and marketing solutions.</p>
						      <a href="#get-quote" className="theme-btn solid-button-one wow fadeInLeft animated" data-wow-delay="1.5s">Get A Quote</a>
						      <a href="tel:+4923639099921" className="theme-btn line-button-one wow fadeInRight animated" data-wow-delay="1.5s">Call Us Now</a>
					    </div> 
				  </div> 
			</div>

      {/* <div className="container">
        <div className="columns is-vcentered is-desktop">
          <div className="column is-5-desktop has-text-centered-touch">
            <SectionHeader
              title={props.title}
              subtitle={props.subtitle}
              size={1}
            />
            <SectionButton
              parentColor={props.color}
              size="medium"
              onClick={props.buttonOnClick}
            >
              {props.buttonText}
            </SectionButton>
          </div>
          <div className="column is-1" />
          <div className="column">
            <figure className="HeroSection__image image">
              <img src={props.image} alt="Illustration" />
            </figure>
          </div>
        </div>
      </div> */}
    </Section>
  );
}

export default SliderSection;
