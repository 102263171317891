import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";


function WhyWebsite(props) {
  return (
    <Section id="why-website" className="SectionComponent hero section is-block is-relative why-need-website">
        <div className="our-service-one pos-r">
				    <div className="shape-wrapper">
                <img src="https://embtel.ca/desievent/images/shape/26.svg" alt="" className="shape-one img-shape" />
                <img src="https://embtel.ca/desievent/images/shape/28.svg" alt="" className="shape-two img-shape" />
                <img src="https://embtel.ca/desievent/images/shape/13.svg" alt="" className="shape-three img-shape" />
                <img src="https://embtel.ca/desievent/images/shape/29.svg" alt="" className="shape-four img-shape" />
                <img src="https://embtel.ca/desievent/images/shape/9.svg" alt="" className="shape-five img-shape" />
                <img src="https://embtel.ca/desievent/images/shape/30.svg" alt="" className="shape-six img-shape" />
                <img src="https://embtel.ca/desievent/images/shape/27.svg" alt="" className="shape-seven img-shape" />
                <img src="https://embtel.ca/desievent/images/shape/31.svg" alt="" className="shape-eight img-shape" />
                <img src="https://embtel.ca/desievent/images/shape/32.svg" alt="" className="shape-nine img-shape" />
				    </div>
		{/* <div class="help-articles"> */}
				<div class="container">
					<div className="inner-wrapper">
					<div class="row">
					<div className="col-md-2"></div>
						<div className="col-md-8">
							<div className="para">
								<h5 class="mb-20">Eine Website ist immer zugänglich.</h5>
								<p>Bessere Erreichbarkeit durch die fortschreitende Technologisierung ist es wichtig einen sinnvollen Nutzen aus den Zeitgeist zu ziehen. Damals hatten wir Telefonbücher, richtig? Heute läuft alles viel subtiler und auch schneller ab. </p>
								<p>Heute gibt es Suchmaschinen, wo das Meiste ohne viel Aufwand nur einen Klick entfernt ist. Vor allem Dingen muss das Gesuchte einfach und schnell erreichbar sein. Das Internet hat dies nun möglich gemacht und bietet mehr als nur eine Kontaktmöglichkeit, sondern stellt auch Informationen zur Verfügung. </p>
								<p>Heutzutage ist es relativ schnell erreichbar, gewünschte Infos aus dem Netz zu ziehen. Es gibt theoretisch für fast alles eine Antwort, wenn nicht sogar für alles.</p>
							</div>
						</div>
						<div className="col-md-2"></div>
						<div className="col-md-2"></div>
						<div className="col-md-8">
							<div className="para">
								<h5 class="mb-20">Kunden können informiert werden.</h5>
								<p>Sein oder Nichtsein, das ist hier die Frage! Heutzutage wollen Ihre Kunden auch was fürs Auge haben und demnach eine einfache Bedienung einer Webseite gewährleistet bekommen. </p>
								<p>Es geht in dieser schnell-lebigen Zeit auch um Bequemlichkeit und gleichzeitig Schnelligkeit. Manchmal kann schon so eine Vorstellung wie eine eierlegende Wollmichsau angesehen werden. </p>
								<p>Ihre Kunden wollen am liebsten gestern gewünschten Kontakt aufgenommen haben.</p>
							</div>
						</div>
						<div className="col-md-2"></div>
						<div className="col-md-2"></div>
						<div className="col-md-8">
							<div className="para">
								<h5 class="mb-20">Plattform, um Ihre Arbeit / Dienstleistungen zu zeigen.</h5>
								<p>Eine Webseite bietet sehr effektiv Vorabinformationen. Im Gegensatz zum guten alten Telefonbuch ist durch eine einfach zu bedienende und funktional hilfreiche Präsenz im weltweiten Netz die Möglichkeit sehr stark erhöht, das die Aufmerksamkeit auf Ihr Unternehmen und auch indirekte Empfehlungen von Besuchern weitergegeben wird. </p>
								<p>Je besser einfacher und aufgeräumter eine Webseite wirkt, umso schneller kommen dadurch neue Kontakte zustande.</p>
							</div>
						</div>
						<div className="col-md-2"></div>
						<div className="col-md-2"></div>
						<div className="col-md-8">
							<div className="para">
							<h5 class="mb-20">Zugänglichkeit.</h5>
							<p>Je nach Unternehmensstruktur und je nach angebotene Dienstleistung sparen Sie zusätzliche Kosten für Immobilien (Büroräume), da Sie bequem von zuhause aus arbeiten können und etwaige Lagerungsfragen durch Just-In-Time-Methoden sehr gering halten. </p>
							<p>Alle anderen Dinge könnten durch strategische Positionierungen umgeleitet werden, dass dadurch die Fixkosten für Ihr Unternehmen gesenkt werden. Fragen Sie uns einfach, wie so etwas für Ihr Unternehmen aussehen könnte</p>
							</div>
						</div>
						<div className="col-md-2"></div>
						<div className="col-md-2"></div>
						<div className="col-md-8">
							<div className="para">
								<h5 class="mb-20">Eine Website kann den Kundenservice verbessern.</h5>
								<p>Wenn Sie ein Produkt haben, können Kunden zu jeder Tageszeit eine Bestellung aufgeben. Dies fördert Ihre Umsatzgeschwindigkeit und Weiterempfehlungen immens.</p>
							</div>
						</div>
						<div className="col-md-2"></div>
						<div className="col-md-2"></div>
						<div className="col-md-8">
							<div className="para">
								<h5 class="mb-20">Holen Sie sich einen Vorsprung vor Ihren Mitbewerbern.</h5>
								<p>Neueste Technologie richtig und bewusst eingesetzt ist ein Werkzeug. So wie bei jedem Hilfsmittel muss Wissen über Funktionsweise und Anwendung vorhanden sein.</p>
							</div>
						</div>
						<div className="col-md-2"></div>
						<div className="col-md-2"></div>
						<div className="col-md-8">
							<div className="para">
								<h5 class="mb-20">Sie sparen langfristig Geld.</h5>
								<p>Durch zusätzliche Suchmaschinenoptimierung sparen Sie viel Geld für zusätzliche Werbung, weil ihre Kunden Sie anhand sorgfältig ausgesuchter / erforschter Suchbegriffe finden.</p>
							</div>
						</div>
						<div className="col-md-2"></div>
						<div className="col-md-2"></div>
						<div className="col-md-8">
							<div className="para">
								<h5 class="mb-20">Ihr Unternehmen wird an Glaubwürdigkeit gewinnen.</h5>
								<p>Durch die strategische Positionierung in Ihrem Marktbereich, erreichen Sie eine höhere Rückkopplung, als wenn Sie auf physischer Ebene Ihr Budget in meist kostspieliger Werbung verprassen. </p>
								<p>Sie haben mehr Geld über, um in wirkungsvolle aktuelle Technologien zu reinvestieren.</p>
							</div>
						</div>
						<div className="col-md-2"></div>
						
						<div className="col-md-2"></div>
						<div className="col-md-8">
							<div className="para">
								<h5 class="mb-20">Minimal lebensfähiges Produkt.</h5>
								<p>Durch MVP (minimum viable product) erhalten Sie maßgeschneiderte Konzepte, wenn Sie in Ihrem Marktsegment z. B. eine spezielle App benötigen, die wir dann für Sie programmieren und nach Veröffentlichung immer weiter darauf ausbauen, bis sie wirklich passend und immer effektiver in Ihrer Unternehmensphilosophie einfließen kann.</p>
							</div>
						</div>
						<div className="col-md-2"></div>
						{/* <div class="col-lg-7 col-md-7 col-12">
							<div class="feature-block-one hover-state mb-40 js-tilt">
								
									
									<p>Bessere Erreichbarkeit durch die fortschreitende Technologisierung ist es wichtig einen sinnvollen Nutzen aus den Zeitgeist zu ziehen. Damals hatten wir Telefonbücher, richtig? Heute läuft alles viel subtiler und auch schneller ab. Heute gibt es Suchmaschinen, wo das Meiste ohne viel Aufwand nur einen Klick entfernt ist. Vor allem Dingen muss das Gesuchte einfach und schnell erreichbar sein. Das Internet hat dies nun möglich gemacht und bietet mehr als nur eine Kontaktmöglichkeit, sondern stellt auch Informationen zur Verfügung. Heutzutage ist es relativ schnell erreichbar, gewünschte Infos aus dem Netz zu ziehen. Es gibt theoretisch für fast alles eine Antwort, wenn nicht sogar für alles.</p>
								
							</div> 
						</div>
						<div class="col-lg-5 col-md-5 col-12">
							<div class="feature-block-one hover-state mb-40 js-tilt">
								<a href="#">
									
									<p>Sein oder Nichtsein, das ist hier die Frage! Heutzutage wollen Ihre Kunden auch was fürs Auge haben und demnach eine einfache Bedienung einer Webseite gewährleistet bekommen. Es geht in dieser schnell-lebigen Zeit auch um Bequemlichkeit und gleichzeitig Schnelligkeit. Manchmal kann schon so eine Vorstellung wie eine eierlegende Wollmichsau angesehen werden. Ihre Kunden wollen am liebsten gestern gewünschten Kontakt aufgenommen haben.</p>
								</a>
							</div>
						</div> 
						<div class="col-lg-6 col-md-6 col-12">
							<div class="feature-block-one hover-state mb-40 js-tilt">
								<a href="#">
									
									<p>Eine Webseite bietet sehr effektiv Vorabinformationen. Im Gegensatz zum guten alten Telefonbuch ist durch eine einfach zu bedienende und funktional hilfreiche Präsenz im weltweiten Netz die Möglichkeit sehr stark erhöht, das die Aufmerksamkeit auf Ihr Unternehmen und auch indirekte Empfehlungen von Besuchern weitergegeben wird. Je besser einfacher und aufgeräumter eine Webseite wirkt, umso schneller kommen dadurch neue Kontakte zustande.</p>
								</a>
							</div>
						</div>
						<div class="col-lg-6 col-md-6 col-12">
							<div class="feature-block-one hover-state mb-40 js-tilt">
								<a href="#">
									
									<p>Je nach Unternehmensstruktur und je nach angebotene Dienstleistung sparen Sie zusätzliche Kosten für Immobilien (Büroräume), da Sie bequem von zuhause aus arbeiten können und etwaige Lagerungsfragen durch Just-In-Time-Methoden sehr gering halten. Alle anderen Dinge könnten durch strategische Positionierungen umgeleitet werden, dass dadurch die Fixkosten für Ihr Unternehmen gesenkt werden. Fragen Sie uns einfach, wie so etwas für Ihr Unternehmen aussehen könnte</p>
								</a>
							</div> 
						</div> 
						<div class="col-lg-4 col-md-6 col-12">
							<div class="feature-block-one hover-state mb-40 js-tilt">
								<a href="#">
									
									<p>Wenn Sie ein Produkt haben, können Kunden zu jeder Tageszeit eine Bestellung aufgeben. Dies fördert Ihre Umsatzgeschwindigkeit und Weiterempfehlungen immens.</p>
								</a>
							</div>
						</div> 
						<div class="col-lg-4 col-md-6 col-12">
							<div class="feature-block-one hover-state mb-40 js-tilt">
								<a href="#">
									
									<p>Neueste Technologie richtig und bewusst eingesetzt ist ein Werkzeug. So wie bei jedem Hilfsmittel muss Wissen über Funktionsweise und Anwendung vorhanden sein.</p>
								</a>
							</div> 
						</div>
						<div class="col-lg-4 col-md-6 col-12">
							<div class="feature-block-one hover-state mb-40 js-tilt">
								<a href="#">
									
									<p>Durch zusätzliche Suchmaschinenoptimierung sparen Sie viel Geld für zusätzliche Werbung, weil ihre Kunden Sie anhand sorgfältig ausgesuchter / erforschter Suchbegriffe finden.</p>
								</a>
							</div> 
						</div> 
						
						<div class="col-lg-6 col-md-6 col-12">
							<div class="feature-block-one hover-state mb-40 js-tilt">
								<a href="#">
									
									<p>Durch die strategische Positionierung in Ihrem Marktbereich, erreichen Sie eine höhere Rückkopplung, als wenn Sie auf physischer Ebene Ihr Budget in meist kostspieliger Werbung verprassen. Sie haben mehr Geld über, um in wirkungsvolle aktuelle Technologien zu reinvestieren.</p>
								</a>
							</div>
						</div> 
						<div class="col-lg-6 col-md-6 col-12">
							<div class="feature-block-one hover-state mb-40 js-tilt">
								
									
									<p>Durch MVP (minimum viable product) erhalten Sie maßgeschneiderte Konzepte, wenn Sie in Ihrem Marktsegment z. B. eine spezielle App benötigen, die wir dann für Sie programmieren und nach Veröffentlichung immer weiter darauf ausbauen, bis sie wirklich passend und immer effektiver in Ihrer Unternehmensphilosophie einfließen kann</p>
								
							</div>
						</div> */}
					</div> 
					</div>
					
				</div> 
			</div>  
      
    </Section>
  );
}

export default WhyWebsite;
