import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";




function OurProjects(props) {
  return (
    <Section className="SectionComponent hero section is-block is-relative projects" id="projects">
        <div className="project-gallery-home-one pt-20 pos-r md-pt-70">
				<div className="container">
					<div className="d-md-flex align-items-center justify-content-between pos-r">
						<div className="theme-title-one">
							<div className="upper-title mb-5">Project</div>
							<h2 className="main-title underline">Project Gallery.</h2>
						</div> 
						<a href="#get-quote" className="theme-btn line-button-one">Get A Free Quote</a>
					</div>
				</div> 
				<div className="container pt-50">
					<div className="columns">
				
						<div className="column is-one-third">
							<div className="item single-project project-one"></div>
							<div className="project-details pt-30">
								<a href="https://jrroofers.com/" target="_blank">
									<h4 className="project-title">JR Roofers</h4>
									<p className="project-platform">ReactJS</p>
								</a>
							</div>
						</div>
						<div className="column is-one-third">
							<div className="item single-project project-two"></div>
							<div className="project-details pt-30">
								<a href="https://mmazelcontractor.com/" target="_blank">
									<h4 className="project-title">M'Mazel Contractor</h4>
									<p className="project-platform">WordPress</p>
								</a>
							</div>
						</div>
						<div className="column is-one-third">
							<div className="item single-project project-three"></div>
							<div className="project-details pt-30">
								<a href="https://fremontbotanicals.com" target="_blank">
									<h4 className="project-title">Fremont Botanicals</h4>
									<p className="project-platform">VueJS + BigCommerce</p>
								</a>
							</div>
						</div>
				</div>
				<div className="columns pt-30">
						<div className="column is-one-third">
							<div className="item single-project project-four"></div>
							<div className="project-details pt-30">
								<a href="https://eezypost.com" target="_blank">
									<h4 className="project-title">EezyPost</h4>
									<p className="project-platform">AngularJS</p>
								</a>
							</div>
						</div>
						<div className="column is-one-third">
							<div className="item single-project project-five"></div>
							<div className="project-details pt-30" >
								<a href="https://gogreennaturalmattress.com/" target="_blank">
									<h4 className="project-title">Go Green Natural Mattress</h4>
									<p className="project-platform">Shopify</p>
								</a>
							</div>
						</div>
						<div className="column is-one-third">
							<div className="item single-project project-six"></div>
							<div className="project-details pt-30">
								<a href="https://baytechrecovery.com/" target="_blank">
									<h4 className="project-title">BayTech Recovery</h4>
									<p className="project-platform">ReactJS</p>
								</a>
							</div>
						</div>
				</div>
						
				{/* <div className="column is-one-third">
						<div className="item">
							<div className="gallery-polar-state">
								<div className="img-holder pos-r">
									<img src="https://embtel.ca/desievent/images/tectonic.png" alt=""/>
									<a href="https://embtel.ca/desievent/images/recruitfy.png" className="icon zoom fancybox" data-fancybox="images" data-caption="My caption">+</a>
								</div> 
							</div>
						</div>
				</div>
				<div className="column is-one-third">
						<div className="item">
							<div className="gallery-polar-state">
								<div className="img-holder pos-r">
									<img src="https://embtel.ca/desievent/images/recruitfy.png" alt=""/>
									<a href="https://embtel.ca/desievent/images/recruitfy.png" className="icon zoom fancybox" data-fancybox="images" data-caption="My caption">+</a>
								</div>
							</div>
						</div>
				</div> */}
				</div>
				</div>
				{/* <div className="slider-item-wrapper">
					<div className="home-gallery-slider">
						
						
						
					</div>
				</div>  */}
			
      
    </Section>
  );
}

export default OurProjects;
