import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";


function OurServices(props) {
  return (
    <Section id="services" className="SectionComponent hero section is-block is-relative our-services german">
        <div className="our-service-one pt-200 pos-r mb-150 md-mb-150 md-pt-140">
				    <div className="shape-wrapper">
					<img src={require('../images/shape/26.svg')} alt="" className="shape-one img-shape" />
                <img src={require('../images/shape/28.svg')} alt="" className="shape-two img-shape" />
                <img src={require('../images/shape/13.svg')} alt="" className="shape-three img-shape" />
                <img src={require('../images/shape/29.svg')} alt="" className="shape-four img-shape" />
                <img src={require('../images/shape/9.svg')} alt="" className="shape-five img-shape" />
                <img src={require('../images/shape/30.svg')} alt="" className="shape-six img-shape" />
                <img src={require('../images/shape/27.svg')} alt="" className="shape-seven img-shape" />
                <img src={require('../images/shape/31.svg')} alt="" className="shape-eight img-shape" />
                <img src={require('../images/shape/32.svg')} alt="" className="shape-nine img-shape" />
				    </div>
				    <div className="container">
					      <div className="inner-wrapper pos-r">
						        <div className="theme-title-one">
							          <h2 className="main-title underline service-title"><span>Lassen Sie uns unsere </span><br/> <span>Dienstleistungen ansehen.</span></h2>
						        </div>
						        <div className="row">
							      <div className="col-lg-4">
								        <div className="feature-block-one mt-300 md-mt-50">
											<img src={require('../images/icon/mobile.png')} alt="" className="img-icon" />
									          <h5 className="pt-30 pb-25 tran3s title">Mobile App-Entwicklung.</h5>
									          <p className="tran3s">Wir bieten kundenspezifische Enterprise Mobility-Lösungen auf Basis von Kunden geschäftlichen Anforderungen.</p>
									          <a href="tel:+4923639099921" className="read-more tran3s"><i className="flaticon-next-1"></i></a>
								        </div>
							      </div>

									<div className="col-lg-4 col-md-6">
											<div className="feature-block-one mb-45 mt-85 md-mt-40">
											<img src={require('../images/icon/wordpress2.png')} alt="" className="img-icon" />
												<h5 className="pt-30 pb-25 tran3s title">CMS-Entwicklung.</h5>
												<p className="tran3s">Wir sind Experten in Wordpress, Drupal, Joomla und anderen CMS.</p>
												<a href="tel:+4923639099921" className="read-more tran3s"><i className="flaticon-next-1"></i></a>
											</div>
											<div className="feature-block-one">
											<img src={require('../images/icon/cart.png')} alt="" className="img-icon" />
												<h5 className="pt-30 pb-25 tran3s title">Entwicklung des E-Commerce</h5>
												<p className="tran3s">Wir haben unseren Kunden geholfen, mehr als 100.000 Dollar + an E-Commerce-Verkäufen zu generieren.</p>
												<a href="tel:+4923639099921" className="read-more tran3s"><i className="flaticon-next-1"></i></a>
											</div>
									</div>

							      	<div className="col-lg-4 col-md-6">
								        <div className="feature-block-one mb-45 md-mt-40">
										<img src={require('../images/icon/icon5.svg')} alt="" className="img-icon" />
									        <h5 className="pt-30 pb-25 tran3s title">Webentwicklung.</h5>
									        <p className="tran3s">Holen Sie sich die besten Anbieter im Bereich Webdesign für Ihre Website-Anforderungen.</p>
											<a href="tel:+4923639099921" className="read-more tran3s"><i className="flaticon-next-1"></i></a>
										</div> 
										<div className="feature-block-one">
										<img src={require('../images/icon/icon6.svg')} alt="" className="img-icon" />
											<h5 className="pt-30 pb-25 tran3s title">Webdesign. </h5>
											<p className="tran3s">Wir bieten erschwingliche benutzer- und SEO-freundliche Webdesign-Alternativen.</p>
											<a href="tel:+4923639099921" className="read-more tran3s"><i className="flaticon-next-1"></i></a>
										</div>
									</div>
								</div>
					</div>
				</div>
			</div>
      
    </Section>
  );
}

export default OurServices;
